import Header from '../atoms/Header';
import { Link } from 'react-router-dom';

export default function Banner() {
    return (
        <>
            <Header />
            <div className='py-2 px-5 w-[80%] text-center mx-auto'>
                <div id='companyInHome' className='text-3xl lg:text-5xl lg:mt-10 placeholder:font-semibold text-center'>
                    Job Portal
                </div>
                <div className='text-[10px] lg:text-xl text-center '>
                    Carikerja memberikan banyak opsi pilihan untuk kamu yang sedang mencari pekerjaan
                </div>
                <div className='divider'></div>
                <div className='flex flex-wrap gap-2 lg:gap-6 justify-center'>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/loker?search=Fullstack'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://i.pinimg.com/originals/f4/75/26/f475267d92efe7b3dcec274f652f17fe.png'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>FullStack Web Developer</h5>
                        </Link>
                    </div>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/loker?search=Back'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://cdn-icons-png.flaticon.com/512/6213/6213731.png'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>BackEnd Web Developer</h5>
                        </Link>
                    </div>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/loker?search=Front'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://w1.pngwing.com/pngs/751/223/png-transparent-digital-marketing-icon-web-design-web-development-frontend-web-development-search-engine-optimization-user-interface-design-web-application-wordpress.png'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>FrontEnd Web Developer</h5>
                        </Link>
                    </div>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/loker?search=Back'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://cdn-icons-png.flaticon.com/512/5065/5065647.png'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>DevOps</h5>
                        </Link>
                    </div>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/loker?search=UI'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://icon-library.com/images/ux-design-icon/ux-design-icon-22.jpg'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>UI/UX Designer</h5>
                        </Link>
                    </div>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/loker'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://as2.ftcdn.net/v2/jpg/02/36/26/39/1000_F_236263910_En4FiJTbTRK8HoRSpT4W9LzLP2CM2pi7.jpg'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>Lihat Selengkapnya </h5>
                        </Link>
                    </div>
                </div>
                <div className='py-5'></div>
                <div id='companyInHome' className='text-3xl lg:text-5xl lg:mt-10 placeholder:font-semibold text-center'>
                    Partner Kami
                </div>
                <div className='text-[10px] lg:text-xl text-center '>Kami Bekerja sama dengan banyak perusahaan yang sedang mencarimu</div>
                <div className='divider'></div>
                <div className='flex flex-wrap gap-2 lg:gap-6 justify-center'>
                    <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
                        <Link to={'/perusahaan'} className='flex flex-col items-center p-5 lg:p-10'>
                            <img
                                className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg hover:animate-bounce'
                                src='https://as2.ftcdn.net/v2/jpg/02/36/26/39/1000_F_236263910_En4FiJTbTRK8HoRSpT4W9LzLP2CM2pi7.jpg'
                                style={{ objectFit: 'cover' }}
                            />
                            <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>Lihat Selengkapnya </h5>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
