import SearchBar from '../atoms/Searchbar';
import HeadCover from '../atoms/HeadCover';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getFetchJobs } from '../../store/actions/actionCreattor';
import EachJobCard from '../atoms/EachJobCard';
import JobModal from '../atoms/JobModal';
import { useSearchParams } from 'react-router-dom';
export default function JobsList() {
    const [searchParams] = useSearchParams();
    const jobs = useSelector((state) => state.jobs);
    const query = searchParams.get('search');
    const query2 = searchParams.get('CompanyId');
    const dispatch = useDispatch();
    useEffect(() => {
        if (query) {
            dispatch(getFetchJobs({ search: query, CompanyId: '' }));
        } else if (query2) {
            dispatch(getFetchJobs({ search: '', CompanyId: query2 }));
        } else {
            dispatch(getFetchJobs());
        }
    }, []);
    const [selectedJob, setSelectedJob] = useState(null);

    return (
        <>
            <HeadCover text='Lowongan Pekerjaan' />
            <JobModal job={selectedJob} />
            <div className='w-[80%] mx-auto flex flex-col gap-2 justify-center'>
                <div className='w-full'>
                    <SearchBar query={query} query2={query2} />
                    <div className='flex gap-4 flex-col lg:flex-row lg:flex-wrap justify-center mt-5 items-center w-full'>
                        {jobs.length === 0 ? (
                            <div className='mt-5'>Maaf Pencarianmu saat ini belum tersedia</div>
                        ) : (
                            jobs.map((job) => {
                                return <EachJobCard key={job.id} job={job} setSelectedJob={setSelectedJob} />;
                            })
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
