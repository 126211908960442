//FOR STORE

import { FETCH_COMPANIES_SUCCESS, FETCH_JOBS_SUCCESS, SET_LOADING } from './actionType';

export const fetchCompaniesSuccess = (payload) => {
    return {
        type: FETCH_COMPANIES_SUCCESS,
        payload: payload,
    };
};

export const fetchJobsSuccess = (payload) => {
    return {
        type: FETCH_JOBS_SUCCESS,
        payload: payload,
    };
};

export const setLoading = (payload) => {
    return {
        type: SET_LOADING,
        payload: payload,
    };
};

//FOR API
const url = 'https://carikerja-backend.mashayyik.dev';

export const getFetchCompanies = () => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const response = await fetch(url + '/companies', {
                method: 'get',
            });
            const json = await response.json();
            if (!response.ok) {
                throw new Error(response);
            }
            dispatch(fetchCompaniesSuccess(json));
        } catch (err) {
            throw err;
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const getFetchJobs = (filter = { search: '', CompanyId: '' }) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            let params = '';
            if (filter) {
                const { search, CompanyId } = filter;
                if (search !== '' && CompanyId !== '') {
                    params = `?CompanyId=${CompanyId}&search=${search}`;
                } else if (CompanyId !== '') {
                    params = `?CompanyId=${CompanyId}`;
                } else if (search !== '') {
                    params = `?search=${search}`;
                }
            } else {
                params = '';
            }
            const response = await fetch(`${url}/jobs` + params, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobsSuccess(json));
        } catch (err) {
            throw err;
        } finally {
            dispatch(setLoading(false));
        }
    };
};
