import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../atoms/Logo';
function Navbar() {
    const [activeNavbar, setActiveNavbar] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 100) {
            setActiveNavbar(true);
        } else {
            setActiveNavbar(false);
        }
    };
    window.addEventListener('scroll', changeBackground);
    const handleClick = (e) => {
        console.log(document.getElementsByTagName(`tabIndex`));
    };
    return (
        <div
            className={
                (activeNavbar ? ` bg-white border-b-2 border-slate-400 shadow-md ` : ' ') +
                ' z-50 navbar top-0 h-[80px] left-0 fixed p-3 bg-transparent'
            }>
            <div className='navbar-start'>
                <div className='dropdown'>
                    <label tabIndex={0} className='btn btn-ghost lg:hidden'>
                        <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h8m-8 6h16' />
                        </svg>
                    </label>
                    <ul tabIndex={0} className='menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52'>
                        <li>
                            <NavLink to={'/'} onClick={handleClick}>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/loker'} onClick={handleClick}>
                                Lowongan Kerja
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/perusahaan'} onClick={handleClick}>
                                Perusahaan
                            </NavLink>
                        </li>
                        <li>
                            <a href='https://carikerja-admin.mashayyik.dev'>Pasang Loker (Browser Dekstop/PC Diharuskan)</a>
                        </li>
                    </ul>
                </div>
                <Link to={'/'} className='text-center lg:text-left lg:ml-10'>
                    <Logo activeNavbar={activeNavbar} />
                </Link>
            </div>
            <div className='navbar-center hidden lg:flex'>
                {activeNavbar && (
                    <ul className='menu menu-horizontal px-1'>
                        <li>
                            <NavLink to={'/'}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/loker'}>Lowongan Kerja</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/perusahaan'}>Perusahaan</NavLink>
                        </li>
                    </ul>
                )}
            </div>
            <div className='navbar-end hidden lg:flex'>
                <a
                    href='https://carikerja-admin.mashayyik.dev'
                    className='bg-blue-600 hover:bg-blue-500 text-white text-[10px] md:text-xl rounded-lg px-3 py-2'>
                    PASANG LOWONGAN
                </a>
            </div>
        </div>
    );
}

export default Navbar;
