import { createBrowserRouter } from 'react-router-dom';
import Banner from '../components/atoms/Banner.jsx';
import CompaniesList from '../components/pages/CompaniesList.jsx';
import HomePage from '../components/pages/HomePage.jsx';
import JobsList from '../components/pages/JobsList.jsx';
const router = createBrowserRouter([
    {
        element: <HomePage />,
        children: [
            {
                path: '/',
                element: <Banner />,
            },
            {
                path: '/loker',
                element: <JobsList />,
            },
            {
                path: '/perusahaan',
                element: <CompaniesList />,
            },
        ],
    },
]);

export default router;
