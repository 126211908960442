import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFetchCompanies } from '../../store/actions/actionCreattor';
import EachCardBanner from '../atoms/EachCardBanner';
import HeadCover from '../atoms/HeadCover';
export default function CompaniesList() {
    const companies = useSelector((state) => state.companies);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFetchCompanies()).then((_) => {});
    }, []);
    return (
        <div>
            <HeadCover text='Perusahaan Partner' />
            <div id='companyInHome' className='text-3xl lg:text-5xl lg:mt-10 placeholder:font-semibold text-center'>
                Bisnis Partner
            </div>
            <div className='text-[10px] lg:text-xl text-center '>Perusahaan yang mempercayakan informasi lowonga kerja pada kami</div>
            <div className='divider'></div>
            <div className='flex gap-2 lg:gap-9 flex-wrap w-full mx-auto justify-center'>
                {companies.map((company) => {
                    return <EachCardBanner key={company.id} company={company} />;
                })}
            </div>
        </div>
    );
}
