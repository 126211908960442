import { useTypewriter } from 'react-simple-typewriter';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchCompanies, getFetchJobs } from '../../store/actions/actionCreattor';
import { useEffect, useState } from 'react';
export default function SearchBar(props) {
    const { query, query2 } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [text] = useTypewriter({
        words: ['Fullstack Web Developer', 'Front End Web Developer', 'Back End Web Developer', 'DevOps', 'UI/UX Designer'],
        loop: 0,
    });
    const [form, setForm] = useState({
        search: '',
        CompanyId: '',
    });
    const companies = useSelector((state) => state.companies);
    useEffect(() => {
        dispatch(getFetchCompanies()).then((_) => {});
        if (query) {
            setForm({
                ...form,
                search: query,
            });
        } else if (query2) {
            setForm({
                ...form,
                CompanyId: query2,
            });
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };
    const handleSearchButton = (e) => {
        e.preventDefault();
        dispatch(getFetchJobs(form));
    };
    const resetHandler = (e) => {
        e.preventDefault();
        setForm({
            search: '',
            CompanyId: '',
        });
        navigate('/loker');
        dispatch(getFetchJobs());
    };
    return (
        <form onSubmit={handleSearchButton}>
            <div className='flex w-full lg:w-[60%] gap-2 justify-center mx-auto flex-wrap lg:flex-nowrap'>
                <label for='countries' class='mb-2 text-sm text-[10px] px-2 font-medium text-gray-900 sr-only  border-2 rounded-xl'>
                    Select an option
                </label>
                <select
                    value={form.CompanyId}
                    onChange={onChangeHandler}
                    name='CompanyId'
                    class='flex-shrink-0 z-10 inline-flex border-2 text-[10px] rounded-md items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 '>
                    <option value='' selected>
                        Choose a companies
                    </option>
                    {companies.map((company) => {
                        return <option value={company.id}>{company.name}</option>;
                    })}
                </select>
                <div className='relative w-full flex gap-1 lg:gap-2'>
                    <input
                        value={form.search}
                        onChange={onChangeHandler}
                        name='search'
                        className='block p-2.5 w-full z-20  text-gray-900 text-sm lg:text-2xl bg-gray-50 border-2 rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                        placeholder={text}
                    />
                    <button
                        type='submit'
                        className='p-2.5 text-2xl font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                        <svg
                            aria-hidden='true'
                            className='w-5 h-5 lg:w-8 lg:h-8'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                stroke-width='2'
                                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                        </svg>
                    </button>
                    <button
                        type='button'
                        onClick={resetHandler}
                        className='p-2.5 text-2xl font-medium text-white bg-red-700 rounded-lg border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-5 h-5 lg:w-8 lg:h-8'>
                            <path
                                fillRule='evenodd'
                                d='M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z'
                                clipRule='evenodd'
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </form>
    );
}
