import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
export default function Header() {
    return (
        <div className='border-gray-200'>
            <div className="flex flex-col gap-3 items-center bg-center bg-cover justify-center h-60 sm:h-96 mb-4 rounded-br-full bg-[url('https://filworx.com/wp-content/uploads/2019/11/Filworx_Job-Portal-Advantages-for-Job-Seekers-and-Employers_Cargullo_Image_01.jpg')]">
                <div className='flex font-[Righteous] decoration-gray-text-gray-900 cursor-pointer z-40'>
                    <span className='text-5xl md:text-9xl font-medium text-blue-600'>Cari</span>
                    <div className='text-5xl md:text-9xl text-gray-900'>
                        <Typewriter
                            words={['Kerja', 'Duit', 'Career']}
                            loop={0}
                            cursor
                            cursorStyle='_'
                            typeSpeed={100}
                            deleteSpeed={100}
                            delaySpeed={2000}
                        />
                    </div>
                </div>
                <Link
                    to={'/loker'}
                    className=' z-40 bg-gray-800/80 px-5 py-1 flex  gap-3 justify-center items-center text-white rounded-full text-[15px] hover:bg-gray-800 cursor-pointer'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-4 h-4 md:w-5 md:h-5'>
                        <path
                            fillRule='evenodd'
                            d='M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z'
                            clipRule='evenodd'
                        />
                        <path d='M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z' />
                    </svg>
                    LIHAT SEMUA LOWONGAN KERJA
                </Link>
                <span id='blackOverlay' className='w-full h-60 sm:h-96 absolute opacity-80 bg-white'></span>
            </div>
        </div>
    );
}
