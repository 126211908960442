import { useEffect, useState } from 'react';

export default function JobModal(props) {
    const { job } = props;

    const [article, setArticle] = useState({
        id: '',
        title: '',
        description: '',
        companyName: '',
        companyLogo: '',
        location: '',
        user: '',
        jobType: '',
        skills: [],
    });
    useEffect(() => {
        if (job) {
            setArticle({
                id: job.id,
                title: job.title,
                description: job.description,
                companyName: job.Company.name,
                companyLogo: job.Company.companyLogo,
                location: job.Company.location,
                user: job.User.name,
                jobType: job.jobType,
                skills: job.Skills,
            });
        }
    }, [props]);
    return (
        <>
            <input type='checkbox' id='job-modal' className='modal-toggle' />
            <label htmlFor='job-modal' className='modal cursor-pointer'>
                <label className='modal-box relative p-3'>
                    <div
                        className='flex flex-col gap-3 items-center rounded-lg bg-center bg-cover justify-center h-[500px] lg:h-[700px] sm:h-96'
                        style={{ backgroundImage: `url(${article.companyLogo})` }}>
                        <div className='text-2xl z-50 font-semibold'>{article.title}</div>
                        <div className='text-md z-50 font-medium'>({article.jobType})</div>
                        <div className='flex flex-col w-full justify-center overflow-y-scroll items-center gap-1 z-40'>
                            <div className='divider before:bg-gray-400 after:bg-gray-400 text-[10px]'>Detail Pekerjaan</div>
                            <div className='text-center text-[12px] lg:text-sm'>{article.description}</div>

                            <div className='divider before:bg-gray-400 after:bg-gray-400 text-[10px]'>Requirement</div>
                            <div className='text-center text-[12px] lg:text-sm w-[70%] flex flex-col lg:gap-5 '>
                                {article.skills.map((skill) => {
                                    return (
                                        <div className='flex justify-between'>
                                            <div>{skill.name}</div>
                                            <div>{skill.level}</div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className='divider before:bg-gray-400 after:bg-gray-400 text-[10px]'>Informasi Perusahaan</div>
                            <div className='text-center w-[70%] text-[12px] lg:text-sm flex flex-col lg:gap-5 '>
                                <div className='flex justify-between'>
                                    <div>Nama</div>
                                    <div>{article.companyName}</div>
                                </div>
                                <div className='flex justify-between'>
                                    <div>Location</div>
                                    <div>{article.location}</div>
                                </div>
                            </div>
                        </div>

                        <span id='blackOverlay' className='w-full h-[500px]  lg:h-[700px]  absolute opacity-95 bg-white'></span>
                    </div>
                </label>
            </label>
        </>
    );
}
