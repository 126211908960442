import { Link } from 'react-router-dom';

export default function EachCardBanner(props) {
    const { company } = props;

    return (
        <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl text-center'>
            <Link to={`/loker?CompanyId=${company.id}`} className='flex flex-col items-center p-5 lg:p-10 hover:animate-bounce'>
                <img
                    className='w-20 h-20 lg:w-44 lg:h-44 mb-3 rounded-full shadow-lg'
                    src={company.companyLogo}
                    alt='Company Image'
                    style={{ objectFit: 'cover' }}
                />
                <h5 className='mt-2 mb-1 text-xl font-medium text-gray-900 '>{company.name}</h5>
                <span className='text-sm text-gray-500 w-[300px]'>{company.location}</span>
            </Link>
        </div>
    );
}
