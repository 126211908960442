import { useState, useEffect } from 'react';
import img1 from '../assets/1.jpg';
import img2 from '../assets/2.jpg';
import img3 from '../assets/3.jpg';
import img4 from '../assets/4.jpg';

export default function HeadCover(props) {
    const { text } = props;
    const images = [img2, img3, img4];
    const [bg, setBg] = useState(img1);
    let i = 0;
    function backgroundChanger() {
        if (i == images.length - 1) {
            i = 0;
        } else {
            i++;
        }
        setBg(images[i]);
    }
    useEffect(() => {
        const interval = setInterval(() => {
            backgroundChanger();
        }, 10000);

        return () => clearInterval(interval);
    }, []);
    return (
        <div className='border-gray-200'>
            <div
                className='flex flex-col gap-3 items-center bg-center bg-cover justify-center h-28 sm:h-56 mb-4'
                style={{ backgroundImage: `url(${bg})` }}>
                <div className='flex font-[Righteous] decoration-gray-text-gray-900 cursor-pointer z-40'>
                    <span className='text-2xl md:text-5xl font-semibold text-blue-600 stroke-2 stroke-white'>{text}</span>
                </div>
                <span id='blackOverlay' className='w-full h-28 sm:h-56 absolute opacity-90 bg-white'></span>
            </div>
        </div>
    );
}
