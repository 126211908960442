import { useState } from 'react';

export default function EachJobCard(props) {
    const { setSelectedJob, job } = props;
    const { Company, Skills, User, createdAt, id, jobType, title, description } = job;
    let color = '';
    return (
        <div className='border-2 md:border-4 w-[90%] lg:w-[40%] lg:p-2 rounded-xl md:flex gap-2 items-start shadow-xl md:shadow-xl'>
            <img src={Company.companyLogo} style={{ width: '200px' }} className='mx-auto md:mx-0 p-0' />
            <div className='flex flex-col justify-between p-4 leading-normal'>
                <h5 className='mb-1 text-md md:text-2xl font-bold tracking-tight text-gray-900 '>{title}</h5>
                <h5 className='mb-2 text-sm md:text-xl font-light tracking-tight text-blue-900 '>{Company.location}</h5>
                <label
                    onClick={() => {
                        setSelectedJob(job);
                    }}
                    htmlFor='job-modal'
                    className='bg-blue-600 hover:bg-blue-400 cursor-pointer text-white text-[10px] p-1 text-center mb-3 rounded-full lg:w-[100px] lg:text-sm'>
                    lihat detail
                </label>
                <p className='mb-4 font-normal text-[10px] md:text-sm text-gray-700 line-clamp-3 pr-4'>{description}</p>
                <div className='flex gap-2 lg:gap-3 flex-wrap text-[10px] justify-center md:text-sm md:justify-start'>
                    {Skills.map((skill) => {
                        switch (skill.level) {
                            case 'beginner':
                                color = 'bg-green-200 text-green-700';
                                break;
                            case 'intermediate':
                                color = 'bg-yellow-200 text-yellow-700';
                                break;
                            case 'advanced':
                                color = 'bg-red-200 text-red-700';
                                break;
                        }
                        return <p className={color + '  px-2 py-0.5 lg:px-2 lg:py-1 text-[10px] rounded-full'}>{skill.name}</p>;
                    })}
                </div>
            </div>
        </div>
    );
}
