import { FETCH_COMPANIES_SUCCESS, FETCH_JOBS_SUCCESS, SET_LOADING } from '../actions/actionType';

const initialState = {
    jobs: [],
    companies: [],
    isLoading: false,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
            };
        case FETCH_JOBS_SUCCESS:
            return {
                ...state,
                jobs: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        default:
            return state;
    }
};

export default rootReducer;
